.box {
  margin-top: 30px;
  width: 1270px;
  height: 300px;
  padding: 45px;
  background: #f1f8ff;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
}

.p-text {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .box {
    width: 90%;
    height: auto;
    padding: 30px;
  }

  .p-text {
    font-size: 16px;
  }
}

/* Media query for mobile devices */
@media screen and (max-width: 480px) {
  .box {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .p-text {
    font-size: 14px;
  }
}


/* Basic responsive styling */
.responsive-img {
  max-width: 120px;
  height: 120px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
}

/* Additional styling for mobile devices */
@media (max-width: 600px) {
  .responsive-img {
      max-width: 30%; /* Adjust this value as needed */
      height: auto;
      margin-left: 1px;

  }
}


@media (max-width: 767.98px) {
  .responsive-padding {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}


.social-media-buttons-container {
  align-items: center;
  margin-top: 21rem; /* 4rem margin top */
  flex-wrap: wrap; /* Enable wrapping */
  gap: 0.1rem; /* Set the gap between items */
  position: fixed;
}

/* Button styles */
.social-media-button {
  text-decoration: none;
  color: hsl(210, 19%, 94%); /* Blue color */
  background-color: #212e4d !important;
  border: 1px solid #f7f8fa;
  border-radius: 50%; /* Rounded shape */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem; /* 1rem right margin */ 
}

.social-media-button:hover {
  background-color: #f1f8ff !important; /* Blue background on hover */
  color: #feaf39; /* White color on hover */
  border: 1px solid black;
}


@media screen and (max-width: 768px) {
  .social-media-button{
    display: none;
  }
}





/* Default styling (hidden on larger screens) */
/* Default styling (hidden on larger screens) */
.social-container {
  display: none;
}

/* Show on mobile devices (landscape phones, 576px and below) */
@media (max-width: 576px) {
  .social-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .pt-2 {
    padding-top: 0.5rem;
  }

  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
  }

  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }

  .social-btn {
    margin-right: 0.5rem; /* Space between buttons */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .social-btn i {
    font-size: 1.25rem; /* Adjust the icon size as needed */
  }

  .social-btn .fab {
    margin-right: 0; /* Ensure there's no extra space around icons */
  }
}


